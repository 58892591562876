.call {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}
	
	&__content {
		position: relative;
		z-index: 1;
		border-radius: 24px;
		padding: 46px 299px 24px 24px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

		@include md {
			padding: 40px;
		}

		@include xs {
			padding: 20px;
			padding-bottom: 180px;
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: -1;
			width: 621px;
			aspect-ratio: 621/452;
			background-image: url("../img/upload/call-bg.png");
			background-position: bottom right -88px;
			background-size: contain;
			background-repeat: no-repeat;

			@include xl {
				width: 500px;
			}

			@include md {
				width: 400px;
			}

			@include xs {
				width: 100%;
				background-size: 320px auto;
				background-position:  bottom center;
				background-image: url("../img/upload/call-bg-mob.png");
			}
		}
	}

	&__title {
		margin-bottom: 14px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 16px;
			font-size: 18px;
		}
	}

	&__list {
		@include list-reset;

		margin-bottom: 13px;

		@include xs {
			margin-bottom: 9px;
		}
	}

	&__list-item {
		position: relative;
		padding-left: 27px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include xs {
			padding-left: 22px;
			font-weight: 400;
			font-size: 15px;
			line-height: 1.3;
			color: #111;
		}

		&::before {
			content: "";
			position: absolute;
			left: 12px;
			top: 12px;
			width: 4px;
			aspect-ratio: 1;
			border-radius: 50%;
			background-color: #111;

			@include xs {
				left: 9px;
				top: 9px;
				width: 3px;
			}
		}
	}

	&__text {
		margin-bottom: 35px;
		max-width: 570px;

		@include md {
			padding-right: 110px;
		}

		@include xs {
			margin-bottom: 28px;
			padding-right: 0;
		}
	}

	&__btn {
		@include xl-up {
			padding-left: 88px;
			padding-right: 88px;
		}

		@include xs {
			width: 100%;
		}
	}
}