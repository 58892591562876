.cases {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		margin-bottom: 44px;
		width: 100%;

		.swiper {
			overflow: visible;
		}

		.swiper-slide {
			width: auto;
			height: auto;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 20px;
		padding: 24px;
		width: 295px;
		height: 100%;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include sm {
			width: 232px;
		}
	}

	&__item-img {
		margin-bottom: 24px;
		border-radius: 50%;
		width: 160px;
		aspect-ratio: 1;
		object-fit: cover;
		object-position: center;

		@include sm {
			width: 120px;
		}
	}

	&__item-name {
		margin-bottom: 17px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;

		@include xl {
			margin-bottom: 12px;
			font-size: 21px;
		}

		@include sm {
			margin-bottom: 11px;
			font-size: 20px;
		}
	}

	&__item-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;

		@include xl {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}
	}

	&__free {
		position: relative;
		overflow: hidden;
		padding: 17px 24px 24px;
		padding-right: 351px;
		border-radius: 24px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

		@include sm {
			padding-right: 200px;
		}

		@include xs {
			border-radius: 16px;
			padding: 21px 20px;
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			height: 100%;
			aspect-ratio: 427/227;
			background-image: url("../img/upload/cases-free.png");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			pointer-events: none;

			@include md {
				background-position: bottom right -130px;
			}

			@include sm {
				width: 500px;
				height: auto;
				background-position: bottom right -160px;
			}

			@include xs {
				width: 302px;
				aspect-ratio: 268/251;
				background-image: url("../img/upload/cases-free-m.png");
				background-position: bottom right;
			}
		}
	}

	&__free-title {
		margin-bottom: 19px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 24px;
			font-size: 18px;
		}

		@include xs {
			margin-bottom: 18px;
		}
	}

	&__free-text {
		margin-bottom: 30px;
		max-width: 628px;
		width: 100%;

		@include xs {
			margin-bottom: 66px;
			max-width: 200px;
		}
	}

	&__free-btn {
		@include xl-up {
			padding-left: 78px;
			padding-right: 78px;
		}
	}
}