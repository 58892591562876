.btn {
	@include btn-reset;

	&-wrapper {
		text-align: center;
	}

	&-default {
		@include btn-reset;

		position: relative;
		z-index: 1;
		border-radius: 12px;
		padding: 17px 48px;
		min-width: 212px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		text-align: center;
		color: #000;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: radial-gradient(128% 1677% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		transition: box-shadow .2s;

		@include lg {
			padding: 14px 34px;
			min-width: 159px;
			font-size: 16px;
			line-height: 18px;
		}

		@include sm {
		}

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			z-index: -1;
			border-radius: 12px;
			background: radial-gradient(128.37% 1677.62% at 46.55% 50%, #FEE98E 0%, #FCCF29 100%);
			opacity: 0;
			transition: 
				opacity .2s;
		}

		&_form {
			padding: 19px 34px;
			border-radius: 16px;

			&::before {
				border-radius: 16px;
			}
		}

		@include hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
		}

		&:disabled {
			color: rgba(#111, .5);
			background: radial-gradient(128% 1677% at 46.55% 50%, rgba(253, 225, 103, .5) 0%, rgba(250, 198, 11, .5) 100%);
			
			&::before {
				opacity: 0;
			}
		}

		&_light {
			background: #fff;

			@include hover {
				box-shadow: none;

				&::before {
					opacity: 0;
				}
			}
		}

		&_transparent {
			background: transparent;
			box-shadow: none;

			@include hover {
				box-shadow: none;

				&::before {
					opacity: 0;
				}
			}
		}
	}

	&-order {
		@include btn-reset;

		position: relative;
		z-index: 1;
		border-radius: 12px;
		padding: 16px 35px;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px; 
		letter-spacing: 0.01em;
		text-transform: uppercase;
		text-align: center;
		color: #000;
		background: radial-gradient(128% 1677% at 46.5% 50%, #FDE167 0%, #FAC60B 100%);

		@include lg {
			padding: 11px 27px;
		}

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			z-index: -1;
			border-radius: 12px;
			background: radial-gradient(128.37% 1677.62% at 46.55% 50%, #FEE98E 0%, #FCCF29 100%);
			opacity: 0;
			transition: 
				opacity .2s;
		}

		@include hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
		}

		&:disabled {
		}
	}

	&-recall {
		@include btn-reset;

		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		padding: 4px;
		padding-left: 65px;
		padding-right: 65px;
		border-radius: 16px;
		min-height: 64px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		text-align: center;
		white-space: nowrap;
		color: #000;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: radial-gradient(128% 1677% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		transition:  box-shadow .2s;

		@include lg {
			min-height: 56px;
			font-size: 16px;
		}

		@include md {
			padding-left: 23px;
			padding-right: 23px;
		}

		&::after {
			content: "";
			position: absolute;
			inset: 0;
			z-index: -1;
			border-radius: 12px;
			background: radial-gradient(128.37% 1677.62% at 46.55% 50%, #FEE98E 0%, #FCCF29 100%);
			opacity: 0;
			transition: 
				opacity .2s;
		}

		&_icon {
			@include md {
				padding-left: 68px;
			}

			&::before {
				content: "";
				position: absolute;
				left: 4px;
				top: 4px;
				border-radius: 12px;
				width: 56px;
				aspect-ratio: 1;
				background-color: #fff;
				background-image: url("../img/icon-phone-black.svg");
				background-position: center center;
				background-size: 32px 32px;
				background-repeat: no-repeat;
	
				@include lg {
					width: 48px;
				}
			}
		}

		&_icon-arr {
			@include md {
				padding-left: 68px;
			}

			&::before {
				content: "";
				position: absolute;
				left: 4px;
				top: 4px;
				border-radius: 12px;
				width: 56px;
				aspect-ratio: 1;
				background-color: #fff;
				background-image: url("../img/icon-down-thin.svg");
				background-position: center center;
				background-size: 32px 32px;
				background-repeat: no-repeat;
	
				@include lg {
					width: 48px;
				}
			}
		}

		@include hover {
			&::after {
				opacity: 1;
			}
		}

		&:active {
		}

		&:disabled {
		}

		&_light {
			background: #fff;

			&::after {
				display: none;
			}

			&::before {
				opacity: 1;
				background-color: #f7f7f7;
			}

			@include hover {
				background: #fff;
				box-shadow: none;
			}
		}
	}

	&-burger {
		@include btn-reset;

		position: relative;
		width: 40px;
		aspect-ratio: 1;
		font-size: 0;
		background: transparent;

		&__inner,
		&::after,
		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			width: 24px;
			height: 2px;
			background: #000;
			transform-origin: center;
			transform: translate(-50%, -50%);
			transition:
				opacity .3s,
				top .3s,
				transform .3s;
		}

		&::before {
			transform: translate(-50%, calc(-50% + 8px));
		}

		&::after {
			transform: translate(-50%, calc(-50% - 8px));
		}

		&__inner {
			// width: 20px;
			transition: opacity .3s;
		}
	}

	&-go-top {
		@include btn-reset;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		border-radius: 8px;
		width: 48px;
		aspect-ratio: 1;
		font-size: 0;
		background: rgba(255, 255, 255, 0.06);

		@include sm {
			width: 40px;
		}

		&::after {
			content: "";
			display: block;
			width: 32px;
			aspect-ratio: 1;
			background-image: url("../img/arrow-up.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	&-slider {
		border-radius: 32px;
		width: 64px;
		height: 64px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include sm {
			width: 48px;
		}

		&::after {
			content: "";
			width: 32px;
			aspect-ratio: 1;
			background-image: url("../img/icon-arrow-right.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;

			@include sm {
				width: 18px;
			}
		}

		&_prev {
			&::after {
				content: "";
				transform: scale(-1, 1);
			}
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
}


.open-menu {
	.btn {
		&-burger {
			&__inner,
			&::after,
			&::before {
			}
	
			&::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
	
			&::after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
	
			&__inner {
				opacity: 0;
			}
		}
	}
}