.advantages {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		counter-reset: advantages;

		@include sm {
			grid-template-columns: 100%;
		}

		@include xs {
			grid-gap: 8px;
		}
	}

	&__item {
		position: relative;
		border-radius: 20px;
		padding: 20px 82px 32px 24px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			padding: 16px 20px 22px;
		}
	}

	&__item-name {
		margin-bottom: 16px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			margin-bottom: 12px;
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			display: flex;
			align-items: center;
			padding-left: 70px;
			min-height: 54px;
			font-size: 18px;
		}

		@include xs {
			padding-left: 70px;
		}
	}

	&__item-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}
	}

	&__item-icon {
		position: absolute;
		right: 24px;
		top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 16px;
		width: 64px;
		aspect-ratio: 1;
		border: 1px solid #FAC60B;

		@include sm {
			right: auto;
			left: 20px;
			top: 20px;
			width: 54px;
		}

		@include xs {
			
		}
	}

	&__item-icon-num {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;
		
		&::before {
			counter-increment: advantages;
			content: counter(advantages);
		}
	}

	&__item-icon-img {
		width: 32px;
		aspect-ratio: 1;
		object-fit: contain;
		object-position: center;

		@include xs {
			width: 24px;
		}
	}
}