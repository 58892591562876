@import "_defs/vars";

// @import "_mixins/_adaptive";
@import "_mixins/_shorts";

@import "_mixins/breakpoints";
@import "_defs/norset";
@import "_defs/fonts";
@import "_defs/libs";

// ==========default styles==================

.html {
	height: 100%;
	font-size: 10px;
}

.body {
	position: relative;
	width: 100%;
	font-family: $default-font;
	font-weight: 400;
	font-size: $font-size;
	line-height: 1.3;
	color: $color;
	background: #fff;
	-webkit-font-smoothing: antialiased; //
	-webkit-text-size-adjust: 100%; //сафари фикс увеличения масштаба при инпутах с шрифтом менее 14px
}

.site {
	position: relative;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
	overflow-x: hidden;
	min-height: 100%;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}

input {
	outline: none
}

@media (max-width: 360px) {
	.j360 {
		color: #fff;
	}
	// Это просто чтобы в девтулсе было это разрешение
}

// ==========default styles==================



// ===========common blocks=================

@import "_common/checker";
@import "_common/beauty-radio";
@import "_common/switcher";
@import "_common/custom-input";
@import "_common/custom-field";
@import "_common/custom-checkbox";
@import "_common/custom-select";

@import "_common/btns";
@import "_common/elements";
@import "_defs/container";

@import "_common/header";
@import "_common/footer";

@import "_common/modals";
// @import	"_common/all_svg";
@import "_common/fixed-menu";

// ===========common blocks=================


// ===========blocks styles=================

@import "_blocks/fixed-bottom";
@import "_blocks/fixed-tow-bottom";
@import "_blocks/banner";
@import "_blocks/promo";
@import "_blocks/quiz";
@import "_blocks/algorithm";
@import "_blocks/advantages";
@import "_blocks/subscription";
@import "_blocks/park";
@import "_blocks/reviews";
@import "_blocks/faq";
@import "_blocks/text-block";
@import "_blocks/partners";
@import "_blocks/services";
@import "_blocks/saving";
@import "_blocks/only-order";
@import "_blocks/help";
@import "_blocks/call";
@import "_blocks/prices";
@import "_blocks/order";
@import "_blocks/cases";
@import "_blocks/steps";
@import "_blocks/survey";
@import "_blocks/partners-gl";
@import "_blocks/rules";
@import "_blocks/advantages-mini";
@import "_blocks/contacts";


// ===========blocks styles=================



// ==========visual helper styles==================

@import "_defs/resp_helpers";

// ==========visual helper styles==================