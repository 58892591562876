@mixin xxxl {
	@media (max-width: #{$xxxl - .5}px) {
		@content;
	}
}

@mixin xxl {
	@media (max-width: #{$xxl - .5}px) {
		@content;
	}
}

@mixin xl {
	@media (max-width: #{$xl - .5}px) {
		@content;
	}
}

@mixin lg {
	@media (max-width: #{$lg - .5}px) {
		@content;
	}
}

@mixin md {
	@media (max-width: #{$md - .5}px) {
		@content;
	}
}

@mixin sm {
	@media (max-width: #{$sm - .5}px) {
		@content;
	}
}

@mixin xs {
	@media (max-width: #{$xs - .5}px) {
		@content;
	}
}


@mixin xl-up {
	@media (min-width: #{$xl}px) {
		@content;
	}
}

@mixin lg-up {
	@media (min-width: #{$lg}px) {
		@content;
	}
}

@mixin md-up {
	@media (min-width: #{$md}px) {
		@content;
	}
}

@mixin sm-up {
	@media (min-width: #{$sm}px) {
		@content;
	}
}

@mixin xs-up {
	@media (min-width: #{$xs}px) {
		@content;
	}
}


@mixin mob {
	@media (max-width: #{$md}) {
		@content;
	}
}
