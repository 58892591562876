.footer {
	border-radius: 48px 48px 0 0;
	padding-top: 33px;
	padding-bottom: 32px;
	background: #272626;

	&_tow {
		@include md {
			padding-bottom: 184px;
		}
	}

	&_index {
		@include md {
			padding-bottom: 120px;
		}
	}

	&__container {
		position: relative;
		padding-left: 16px;
		padding-right: 16px;
	}

	@include xs {
		border-radius: 16px 16px 0 0;
		padding-top: 16px;
	}

	&__top {
		margin-bottom: 22px;

		@include xs {
			margin-bottom: 16px;
		}
	}

	&__logo {
		width: 242px;
		aspect-ratio: 242/56;

		@include sm {
			width: 178px;
		}


	}

	&__menu {
		margin-bottom: 36px;

		@include xs {
			margin-bottom: 30px;
		}
	}

	&__menu-item {
		position: relative;
		border-bottom: 1px solid rgba(82, 82, 82, 0.6);

		&_has-child {
			padding-right: 30px;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 12px;
				width: 24px;
				aspect-ratio: 1;
				background-image: url(../img/icon-down-light.svg);
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				cursor: pointer;
				transition: transform .2s;
			}
		}

		&_child-open {
			.footer__menu-child {
				max-height: none;
			}

			&::after {
				transform: rotate(180deg);
			}
		}
	}
	
	&__menu-link {
		display: inline-block;
		padding-top: 13px;
		padding-bottom: 13px;
		width: 100%;
		font-weight: 700;
		font-size: 16px;
		color: #fff;

		@include xs {
			padding-top: 14px;
			padding-bottom: 14px;
			font-weight: 400;
			font-size: 15px;
			line-height: 19px; 
		}
	}

	&__menu-child {
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;
	}

	&__menu-child-inner {
		display: flex;
    gap: 114px;
		padding-top: 11px;
		padding-bottom: 36px;

		@include xl {
			gap: 100px;
		}

		@include lg {
			gap: 50px;
		}

		@include md {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 30px;
		}

		@include sm {
			grid-template-columns: 100%;
		}
	}

	&__menu-child-list {
		@include list-reset;

		display: flex;
		flex-direction: column;
		gap: 9px;

		@include lg {
			gap: 8px;
		}
	}

	&__menu-child-item-link {
		font-weight: 400;
		font-size: 16px;
		color: rgba(#fff, .5);

		@include lg {
			font-size: 15px;
		}
	}

	&__contacts {
		display: flex;
		gap: 40px;
		align-items: flex-start;
		margin-bottom: 45px;

		@include md {
			position: relative;
			align-items: flex-end;
			gap: 20px;
		}

		@include xs {
			flex-direction: column;
			gap: 16px;
			margin-bottom: 30px;
		}
	}

	&__contact-items {
		display: grid;
		width: 503px;
		max-width: 100%;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 21px 38px;

		@include lg {
			grid-template-columns: 100%;
		}

		@include xs {
			width: 100%;
			gap: 13px;
		}
	}

	&__contact-name {
		margin-bottom: 4px;
		font-weight: 600;
		font-size: 16px;
		color: #fff;

		@include sm {
			font-size: 12px;
		}
	}

	&__contact-content {
		position: relative;
		padding-left: 32px;
	}

	&__contact-icon {
		position: absolute;
		left: 0;
		top: 50%;
		width: 24px;
		aspect-ratio: 1;
		transform: translateY(-50%);

	}

	&__contact-value {
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #fbd233;

		@include sm {
			font-size: 16px;
		}
	}

	&__contact-extra {
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		color: #87868a;

		@include sm {
			font-size: 10px;
		}
	}

	&__address {
		width: 462px;
		margin-right: auto;

		@include md {
			padding-top: 50px;
		}

		@include xs {
			position: relative;
			padding-top: 0;
			padding-left: 32px;
		}
	}

	&__address-label {
		margin-bottom: 4px;
		font-weight: 600;
		font-size: 16px;
		color: #fff;

		@include sm {
			font-size: 12px;
		}
	}

	&__address-value {
		position: relative;
		margin-bottom: 8px;
		padding-left: 32px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #fff;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}

		@include xs {
			position: static;
			padding-left: 0;
			font-style: italic;
			font-size: 14px;
			font-weight: 400;
			text-transform: none;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;	
			top: 0px;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-pin.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;

			@include xs {
				top: 8px;
			}
		}
	}

	&__address-extra {
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		color: #87868a;

		@include sm {
			font-size: 12px;
		}

		@include xs {
			margin-left: -32px;
		}
	}


	&__socials {
		@include list-reset;

		display: flex;
		gap: 4px;

		@include md {
			position: absolute;
			right: 0;
			top: 0;
		}

		@include xs {
			padding-top: 18px;
		}

		&::before {
			content: attr(data-text);
			position: absolute;
			left: 0;
			top: 0;
			display: none;
			font-weight: 600;
			font-size: 12px;
			color: #fff;

			@include xs {
				display: block;
			}
		}
	}
	
	&__socials-item {
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		aspect-ratio: 1;
		font-size: 0;
		background: rgba(255, 255, 255, 0.06);

		@include xs {
			width: 40px;
		}
	}

	&__socials-img {
		width: 32px;
		aspect-ratio: 1;

		@include xs {
			width: 30px;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		gap: 16px;

		@include md {
			position: relative;
			flex-direction: column;
			align-items: flex-start;
		}

		@include sm {
			gap: 8px;
		}

		@include xs {
			gap: 3px;
		}
	}

	&__rights {
		margin-right: auto;
		font-weight: 400;
		font-size: 16px;
		color: #fff;

		@include md {
			order: 2;
		}

		@include sm {
			font-size: 12px;
		}
	}

	&__politic,
	&__terms {
		font-weight: 400;
		font-size: 16px;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		color: #fff;

		@include sm {
			font-size: 12px;
		}
	}

	&__go-top {
		position: absolute;
		top: 0;
		right: 20px;

		@include md {
			top: auto;
			right: 0;
			bottom: 0;
		}
	}
}