.partners {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 55px;
	}

	// &__items {
	// 	display: grid;
	// 	grid-template-columns: repeat(2, 1fr);
	// 	grid-gap: 20px;
	// }

	&__slider {
		position: relative;

		.swiper {
			overflow: visible;
		}

		.swiper-slide {
			opacity: 0;
			width: auto;

			&-active {
				opacity: 1;
			}

			&-next {
				opacity: 1;
			}
		}
	}
	&__slider-wrapper {
		overflow: hidden;
		margin: -30px;
		padding: 30px;
		width: calc(100% + 60px);
		height: calc(100% + 60px);
	}

	&__item {
		width: 610px;
		max-width: 100%;
		padding: 16px 24px;
		border-radius: 20px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include sm {
			width: 320px;
			padding: 12px 20px;
		}
	}

	&__item-logo {
		margin-bottom: 24px;
		display: inline-block;
		width: 100%;

		@include sm {
			margin-bottom: 5px;
		}

	}

	&__item-logo-img {
		width: 100%;
		aspect-ratio: 562/110;
		object-fit: contain;
		object-position: center;

		@include sm {
			align-self: 280/56;
		}
	}

	&__item-addresses {
		@include list-reset;

		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	&__item-address {
		position: relative;
		padding-left: 28px;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			padding-left: 19px;
			font-size: 12px;
			line-height: 17px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			aspect-ratio: 1;
			font-size: 0;
			background-image: url("../img/icon-pin-dark.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;

			@include sm {
				width: 12px;
			}
		}
	}

	&__slider-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include sm {
			margin-top: -2px;
		}

		&_prev {
			left: -32px;

			@include sm {
				right: -13px;
			}
		}

		&_next {
			right: -32px;

			@include sm {
				right: -13px;
			}
		}
	}
}