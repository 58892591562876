.switcher {
	&__input {
		@include visually-hidden;

		&:checked {
			& + .switcher {
				&__label {
					.switcher {
						&__fake {

							&::after {
								opacity: 1;
							}

							&::before {
								transform: translateX(27px);
								// 59-26=33-6=27
							}
						}
					}
				}
			}
		}
	}

	&__label {
		position: relative;
		display: inline-flex;
		align-items: center;
		padding-left: 75px;
		min-height: 32px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.1;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
		}
	}

	&__fake {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		border-radius: 50px;
		width: 59px;
		height: 32px;
		background-color: rgba(#1E222E, 0.24);
		cursor: pointer;

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			inset: 0;
			opacity: 0;
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			transition: opacity .2s;
			
		}
		
		&::before {
			content: "";
			position: absolute;
			left: 3px;
			top: 3px;
			width: 26px;
			aspect-ratio: 1;
			border-radius: 100%;
			background-color: #fff;
			transition: transform .2s;
		}
	}
}