.from {
	&-xl {
		@include xl {
			display: none;
		}
	}

	&-lg {
		@include lg {
			display: none;
		}
	}

	&-md {
		@include md {
			display: none;
		}
	}

	&-sm {
		@include sm {
			display: none;
		}
	}

	&-xs {
		@include xs {
			display: none;
		}
	}
}

.to {
	&-xl {
		@include xl-up {
			display: none;
		}
	}

	&-lg {
		@include lg-up {
			display: none;
		}
	}

	&-md {
		@include md-up {
			display: none;
		}
	}

	&-sm {
		@include sm-up {
			display: none;
		}
	}

	&-xs {
		@include xs-up {
			display: none;
		}
	}
}