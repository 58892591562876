.beauty-radio {

	&__input {
		@include visually-hidden;

		&:checked {
			& + .beauty-radio {
				&__label {
					border-color: #FAC60B;

					&::before {
						border-color: #FAC60B;
						background: #fff;
					}
				}
			}
		}
	}

	&__label {
		position: relative;
		display: inline-flex;
		align-items: center;
		border: 1px solid rgba(17, 17, 17, 0.4);
		border-radius: 68px;
		padding: 12px;
		padding-right: 30px;
		padding-left: 72px;
		height: 56px;
		width: 100%;
		font-style: italic;
		font-weight: 800;
		font-size: 20px;
		line-height: 22px;
		color: #111;
		cursor: pointer;

		@include lg {
			padding-right: 20px;
			padding-left: 52px;
			height: 48px;
			font-size: 16px;
			line-height: 18px; 
		}

		@include xs {
		}

		&::before {
			content: "";
			position: absolute;
			left: 12px;
			top: 12px;
			border-radius: 100%;
			border: 9px solid #f1f4f9;
			width: 32px;
			aspect-ratio: 1;
			background: #f1f4f9;

			@include lg {
				width: 24px;
				border-width: 7px;
			}
		}
	}
}