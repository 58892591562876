.park {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__text {
		margin-bottom: 32px;
		width: 610px;
		max-width: 100%;

		@include xs {
			margin-bottom: 19px;
		}
	}

	&__slider {
		margin-bottom: 28px;

		@include xs {
			margin-bottom: 23px;
		}

		.swiper {
			overflow: visible;
		}

		.swiper-slide {
			width: auto;
		}	
	}

	&__slide {
		width: 370px;
		aspect-ratio: 370/303;
		border-radius: 16px;
		object-fit: cover;
		object-position: center;

		@include md {
			width: 300px;
		}

		@include sm {
			width: 254px;
		}

		@include xs {
			aspect-ratio: 254/186;
		}
	}

	&__post-text {
		margin-bottom: 9px;

		@include xs {
			margin-bottom: 4px;
		}
	}

	&__list {
		@include list-reset;

		display: flex;
		flex-direction: column;
		// gap: 0px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}
	}

	&__list-item {
		position: relative;
		padding-left: 28px;

		@include xs {
			padding-left: 21px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 12px;
			top: 11px;
			width: 4px;
			aspect-ratio: 1;
			border-radius: 50%;
			background-color: #111;

			@include xs {
				top: 8px;
				left: 10px;
			}
		}
	}
}