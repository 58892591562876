.modals {
	z-index: 15;
}

.modal {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
	display: none;
	overflow-x: hidden;
	overflow-y: auto;
	justify-content: center;
	width: 100%;
	height: 100%;

	&_active {
		display: flex;
	}

	&__inner {
		position: relative;
		flex-direction: column;
		align-items: center;
		vertical-align: middle;
		margin-top: auto;
		margin-bottom: auto;
		border-radius: 8px;
		padding: 20px;
		width: 660px;
		max-width: calc(100% - 32px);
		background-color: #fff;
	}

	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, .5);
	}

	&__close {
		position: absolute;
		right: 16px;
		top: 16px;
		border: none;
		font-size: 0;
		width: 40px;
		aspect-ratio: 1;
		background-color: transparent;
		background-image: url("../img/icon-close.svg");
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;

		@include md {
			right: 12px;
			top: 12px;
			width: 32px;
		}
	}

	&-bottom {
		&__inner {
			border-radius: 32px;
			padding: 40px 48px 48px;
			max-width: 100%;
			width: 548px;
			box-shadow: 0 -5px 32px 10px rgba(0, 0, 0, 0.2);

			@include md {
				margin-bottom: 0;
				padding: 55px 16px 20px;
				border-radius: 32px 32px 0 0;
				text-align: center;
			}

			&_big-bottom {
				@include md {
					padding-bottom: 64px;
				}
			}
		}

		&__title {
			margin-bottom: 16px;

			&_center {
				text-align: center;
			}
		}

		&__text {
			margin-bottom: 61px;
			font-weight: 400;
			font-size: 18px;
			line-height: 1.3;
			color: #111;

			@include lg {
				font-size: 16px;
			}

			@include sm {
				margin-bottom: 51px;
				font-size: 14px;
				line-height: 18px;
			}

			&_dif-order {
				margin-bottom: 74px;

				@include sm {
					margin-bottom: 30px;
				}
			}


			&_center {
				text-align: center;
			}
		}

		&__form {
			display: flex;
			flex-direction: column;
			gap: 8px;
			
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}

		&__form-mess {
			margin-bottom: 12px;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.3;
			color: #111;
		}

		&__phone {
			position: relative;
			display: inline-block;
			margin-bottom: 8px;
			padding-left: 32px;
			font-weight: 700;
			font-size: 16px;
			line-height: 1.2;
			letter-spacing: 0.01em;
			text-transform: uppercase;
			text-align: center;
			color: #000;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: -4px;
				width: 25px;
				aspect-ratio: 1;
				background-image: url("../img/icon-phone-black.svg");
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}

		&__socials-title {
			margin-bottom: 8px;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.3;
			color: #111;
		}

		&__socials-items {
			@include list-reset;

			display: flex;
			gap: 8px;

			@include md {
				justify-content: center;
			}
		}

		&__socials-item {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			aspect-ratio: 1;
		}

		&__socials-img {
			width: 32px;
			aspect-ratio: 1;
			object-fit: contain;
			object-position: center;
		}

		&__ok {
			width: 100%;
		}

		&__success {
			margin-bottom: 8px;
			text-align: center;
		}

		&__success-img {
			width: 90px;
			aspect-ratio: 1;
			object-fit: contain;
			object-position: center;
		}
	}
}