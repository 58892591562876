.reviews {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__services {
		margin-bottom: 32px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 38px;
		padding: 20px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			margin-bottom: 28px;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
		}
	}

	&__service {
		// width: 233px;
		display: flex;
		align-items: center;
		gap: 16px;
	}

	&__service-icon {
		width: 64px;
		aspect-ratio: 1;
		object-fit: contain;
		object-position: center;

		@include sm {
			width: 48px;
		}
	}

	&__service-info {
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	&__service-stars {
		display: flex;
		font-size: 0;
	}

	&__service-star {
		width: 24px;
		aspect-ratio: 1;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;

		@include sm {
			width: 16px;
		}
	}

	&__service-grade {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include sm {
			font-size: 15px;
			line-height: 1.3; 
		}

		b {
			font-weight: 800;
		}
	}

	&__subtitle {
		margin-bottom: 24px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 21px;
			font-size: 18px;
		}
	}

	&__items {
		.swiper {
			overflow: visible;
		}

		.swiper-slide {
			width: auto;
		}
	}

	&__item {
		border-radius: 24px;
		width: 370px;
		padding: 20px 24px 26px 24px;
		background: #eceff4;

		@include md {
			width: 300px;
		}

		@include sm {
			width: 254px;
		}

		@include xs {
			border-radius: 16px;
			padding: 20px;
			width: 306px;
		}
	}

	&__item-author {
		display: flex;
		align-items: center;
		gap: 12px;
		margin-bottom: 9px;

		@include xs {
			margin-bottom: 11px;
		}
	}

	&__item-author-photo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		aspect-ratio: 1;
		border-radius: 50%;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.3;
		text-align: center;
		color: #fff;
		background-color: #8fa6f5;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;


		@include xs {
			width: 40px;
		}
	}

	&__item-author-name {
		font-weight: 700;
		font-size: 18px;
		line-height: 1.3;
		color: #111;

		@include xs {
			font-size: 15px;
			line-height: 19px; 
		}
	}

	&__item-text {
		margin-bottom: 13px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
			letter-spacing: normal;
		}
	}

	&__item-link {
		position: relative;
		padding-bottom: 6px;
		font-weight: 600;
		font-size: 18px;
		line-height: 1.3;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		}
	}
}