.help {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 19px 20px;

		@include md {
			grid-template-columns: repeat(2, 1fr);
		}

		@include sm {
			grid-template-columns: 100%;
		}

		@include xs {
			grid-gap: 8px;
		}
	}

	&__item {
		border-radius: 20px;
		padding: 24px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			padding: 20px;
		}
	}

	&__item-img {
		margin-bottom: 19px;
		width: 100%;
		aspect-ratio: 352/195;
		object-fit: cover;
		object-position: center;

		@include xs {
			margin-bottom: 16px;
			aspect-ratio: 280/150;
		}
	}

	&__item-name {
		margin-bottom: 16px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;


		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 20px;
		}
	}
	
	&__item-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 19px;
			letter-spacing: normal;
		}
	}
}