.checker {
	position: relative;

	&__input {
		@include visually-hidden;

		&:checked {
			& + .checker {
				&__label {
					border-color: #FDE167;
					background: #fff;
				}
			}
		}
	}

	&__label {
		display: inline-block;
		border: 2px solid #f1f4f9;
		width: 100%;
		border-radius: 16px;
		padding: 15px 16px;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: #111;
		background: #f1f4f9;
		cursor: pointer;

		@include lg {
			padding: 10px 12px;
			font-size: 16px;
			line-height: 20px; 
		}
		
		@include sm {
			font-size: 15px;
			line-height: 20px; 
		}

		@include xs {
			border-radius: 12px;
		}

		b {
			font-style: italic;
			font-weight: 800;
		}
	}

	&_icon {
		.checker {
			&__label {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 24px;
				flex-direction: column;
				gap: 7px;
				padding: 10px 16px;

				@include lg {
					gap: 3px;
				}

				@include sm {
					gap: 6px;
				}

				@include xs {
					border-radius: 16px;
					gap: 2px;
					padding: 3px 16px;
				}
			}
		}
	}

	&__icon {
		width: 68px;
		aspect-ratio: 1;

		@include lg {
			width: 50px;
		}

		@include sm {
			width: 45px;
		}
	}

	&__extra {
		position: absolute;
		right: 12px;
		bottom: 12px;
		display: inline-block;
		border-radius: 32px;
		padding: 8px 14px;
		font-weight: 700;
		font-size: 16px;
		line-height: 1.3;
		color: #111;
		background: #fff;

		@include lg {
			right: 8px;
			bottom: 8px;
			padding: 8px 6px;
			font-size: 12px;
		}

		@include sm {
			right: 4px;
			bottom: 4px;
			font-size: 10px;
		}

		@include xs {
			padding: 5px 6px;
			bottom: 3px;
		}
	}

	&_wide-checked {
		.checker {
			&__input {
				&:checked {
					& + .checker {
						&__label {
							font-weight: 800;
						}
					}
				}
			}
		}
	}

	&__tooltip {
		position: absolute;
		right: 22px;
		top: 16px;

		@include lg {
			right: 16px;
			top: 9px;
		}

		@include sm {
			top: 12px;
		}
	}
}