.text-block {
	&_indent {
		margin-top: 100px;
		margin-bottom: 100px;
	
		@include lg {
			margin-top: 60px;
			margin-bottom: 60px;
		}
	
		@include sm {
			margin-top: 48px;
			margin-bottom: 48px;
		}
	}

	&_negative-margin {
		margin-top: -20px;
		margin-bottom: -20px;

		@include md {
			margin-top: -3px;
			margin-bottom: -3px;
		}
	}

	&__text {
		width: 100%;
		max-width: 901px;
	}
}