.promo {
	margin-top: 104px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;

		@include md {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		@include sm {
			width: 320px;
			margin: 0 auto;
			grid-gap: 8px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 19px 24px 24px;
		aspect-ratio: 400/292;
		border-radius: 24px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background-color: #272626;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;

		@include md {
			width: 320px;
		}

		@include xs {
			border-radius: 16px;
			padding: 17px 20px 21px;
			aspect-ratio: 320/190;
		}
	}

	&__item-name {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.1;
		color: #fff;

		@include lg {
			font-size: 18px;
		}

		&_dark {
			color: #111;
		}

		b {
			font-style: italic;
			background: radial-gradient(1677.62% 128.37% at 46.55%  50.00%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	&__item-btn {
		margin-top: auto;
	}
}