.contacts {
	margin-top: 51px;
	margin-bottom: 100px;

	@include sm {
		margin-top: 16px;
		margin-bottom: 58px;
	}

	&__title {
		// margin-bottom: 64px;
	}

	&__address {
		margin-bottom: 30px;
	}

	&__map {
		margin-bottom: 30px;
		border-radius: 20px;
		overflow: hidden;
		width: 100%;
		aspect-ratio: 3/1;
		background-color: #ccc;

		@include md {
			aspect-ratio: 2/1;
		}

		@include sm {
			aspect-ratio: 1;
		}
	}

	&__text {
		margin-bottom: 40px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
		
		@include md {
			grid-template-columns: 100%;
		}
	}

	&__item {
		border-radius: 32px;
		padding: 30px;
		box-shadow: 0 4px 32px 3px rgba(0, 0, 0, 0.05);
		background: #fff;

		@include sm {
			border-radius: 16px;
		}
	}

	&__item-name {
		margin-bottom: 24px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
		}

		@include sm {
			margin-bottom: 16px;
			letter-spacing: normal;
			font-size: 18px;
			line-height: 1.1;
		}
	}

	&__item-value {
		position: relative;
		margin-bottom: 8px;
		padding-left: 36px;
		font-weight: 700;
		font-size: 20px;
		color: #000;

		@include lg {
			padding-left: 26px;
			font-size: 16px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 2px;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-phone.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;

			@include lg {
				width: 20px;
			}
		}
	}

	&__item-shedule {
		font-weight: 400;
		font-size: 16px;
		color: #000;
	}
}