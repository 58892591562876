.steps {
	border-radius: 0 0 64px 64px;
	padding-top: 51px;
	padding-bottom: 40px;
	background: #f1f4f9;

	@include lg {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	@include sm {
		border-radius: 0;
	}

	@include xs {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 44px;
		padding-top: 26px;
		counter-reset: steps-item;

		@include md {
			grid-template-columns: 100%;
		}

		@include sm {
			padding-top: 0;
			grid-gap: 40px;
		}
	}

	&__item {
		position: relative;
		border-radius: 20px;
		padding: 58px 24px 40px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		min-height: 202px;
		background: #fff;

		@include md {
			padding: 24px;
		}

		@include xs {
			padding: 17px 20px;

		}
		
		&::before {
			counter-increment: steps-item;
			content: "0" counter(steps-item);
			position: absolute;
			left: 24px;;
			top: -26px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			width: 69px;
			aspect-ratio: 1;
			font-weight: 700;
			font-size: 24px;
			line-height: 1.1;
			color: #111;
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

			@include md {
				left: auto;
				right: 24px;
			}

			@include sm {
				top: -16px;
				right: 16px;
				width: 56px;
			}
		}
		
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 100%;
			margin-left: 6px;
			display: none;
			width: 32px;
			aspect-ratio: 1;
			background-image: url("../img/icon-double-arrow.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			transform: translateY(-50%);

			@include md {
				top: 100%;
				left: 50%;
				margin-left: 0;
				margin-top: 7px;
				transform: translateX(-50%) rotate(90deg);
			}

			@include xs {
				margin-top: 5px;
			}
		}

		&:not(:last-child) {
			&::after {
				content: "";
				display: block;
			}
		}
	}

	&__item-name {
		margin-bottom: 16px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 16px;
			font-size: 18px;
		}
	}

	&__item-text {
		
	}

	&__cards {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 52px;
		margin-top: 42px;

		@include sm {
			margin-top: 25px;
		}

		@include xs {
			gap: 33px;
		}
	}

	&__card {
	}

	&__card-img {
		max-width: 100px;
		object-fit: contain;
		object-position: center;

		@include xs {
			max-width: 60px;
		}
	}
}