.advantages-mini {
	padding-top: 64px;
	padding-bottom: 95px;
	background: #f1f4f9;

	@include sm {
		padding-top: 49px;
		padding-bottom: 50px;
	}

	&__title {
		margin-bottom: 60px;
		text-align: center;

		@include xs {
			margin-bottom: 30px;
			text-align: left;
		}
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 20px;
		margin-bottom: 55px;

		@include md {
			grid-template-columns: repeat(2, 1fr);
		}

		@include sm {
		}

		@include xs {
			grid-template-columns: 100%;
			margin-bottom: 32px;
			grid-gap: 8px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 18px;
		border-radius: 40px;
		padding: 32px 16px;
		box-shadow: 0 4px 32px 10px rgba(255, 255, 255, 0.02), 0 4px 32px 10px rgba(255, 255, 255, 0.02), 4px 4px 32px 10px rgba(255, 255, 255, 0.1);
		background: #fff;

		@include sm {
			border-radius: 24px;
		}

		@include xs {
			position: relative;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 37px 16px;
			padding-left: 104px;
		}
	}

	&__item-icon {
		border-radius: 16px;
		width: 64px;
		aspect-ratio: 1;
		display: flex;
    align-items: center;
    justify-content: center;
		border: 1px solid #fac60b;

		@include xs {
			position: absolute;
			left: 24px;
			top: 24px;
		}
	}

	&__item-icon-img {
		width: 32px;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
	}

	&__item-name {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 18px;
		}

		@include xs {
			text-align: left;
		}

	}

	&__btn {
		width: 457px;
		max-width: 100%;
	}
}