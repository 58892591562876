.custom-input {
	&__input {
		border: 1px solid rgba(17, 17, 17, 0.4);
		border-radius: 16px;
		padding: 17px 24px;
		width: 100%;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px; 
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #111;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;
		transition: 
			border-color .2s,
			box-shadow .2s;

		@include lg {
			padding: 18px 24px;
			font-size: 16px;
			line-height: 19px;
		}

		&::placeholder {
			color: #777;
		}

		@include hover {
			box-shadow: none;
		}

		&:focus {
			border-color: #111;
		}

		&:invalid {
			border-color:#f57171;
		}
	}
}