.visually-hidden {
	position: fixed;
	transform: scale(0);
}


.title {
	&-main {
		font-weight: 800;
		font-size: 48px;
		line-height: 1.1;
		color: #111;

		&_big {
			font-size: 56px;
		}

		@include lg {
			font-size: 36px;
		}

		@include sm {
			font-size: 28px;
		}

		&_offset {
			margin-bottom: 48px;

			@include lg {
				margin-bottom: 35px;
			}

			@include xs {
				margin-bottom: 32px;
			}
		}

		&_offset-small {
			margin-bottom: 24px;

			@include xs {
				margin-bottom: 20px;
			}
		}

		&_small-mob {
			@include sm {	
				font-size: 24px;
			}
		}
	}

	&-default {
		font-weight: 800;
		font-size: 40px;
		line-height: 1.1;
		color: #111;

		@include lg {
			font-size: 36px;
		}

		@include sm {
			font-size: 28px;
		}

		&_offset {
			margin-bottom: 48px;

			@include lg {
				margin-bottom: 35px;
			}

			@include xs {
				margin-bottom: 32px;
			}
		}

		&_offset-small {
			margin-bottom: 24px;

			@include xs {
				margin-bottom: 20px;
			}
		}
	}

	&-common {
	}
}

.text {
	&-common {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 19px;
			letter-spacing: normal;
		}

		p,
		ol,
		ul {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		ol {
			list-style-position: inside;
		}
	}

	// по идее не используется нигде. просто для копирования адаптива
	&-common-2 {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 18px;
		}
	}
}

.open-menu {
	overflow: hidden;
}

.logo {
	display: inline-block;

	&__img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
}

.tooltip {
	display: inline-block;
	width: 24px;
	aspect-ratio: 1;
	border-radius: 50%;
	font-size: 0;
	background-image: url("../img/icon-tooltip.svg");
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;

	@include sm {
		width: 20px;
	}

	&_gray {
		background-image: url("../img/icon-tooltip-gray.svg");
	}

	&_transparent {
		background-image: url("../img/icon-tooltip-transparent.svg");
	}

	&_big {
		width: 32px;
	}
}

.underline {
	position: relative;
	z-index: 1;
	
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -1;
		width: 100%;
		height: 5px;
		background: radial-gradient(128% 1677% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
	}
}


.site {
	@include md {
		padding-top: 80px;
	}

	@include sm {
		padding-top: 66px;
	}

	@include xs {
		padding-top: 60px;
	}
}