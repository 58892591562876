.saving {
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 64px;
	padding-bottom: 80px;
	background: #f1f4f9;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 55px;
		padding-top: 40px;
		padding-bottom: 48px;
	}

	&__content {
		display: none;
		grid-template-columns: auto 387px;
		grid-gap: 40px;
		border-radius: 32px;
		padding: 24px 21px;
		box-shadow: 0 4px 32px 10px rgba(255, 255, 255, 0.02), 0 4px 32px 10px rgba(255, 255, 255, 0.02), 4px 4px 32px 10px rgba(255, 255, 255, 0.1), 0 4px 32px 3px rgba(0, 0, 0, 0.05);
		background: #fff;

		@include lg {
			grid-template-columns: auto 350px;
			grid-gap: 20px;
		}

		@include md {
			justify-content: space-between;
		}

		@include sm {
			flex-direction: column;
			gap: 32px;
		}

		@include xs {
			padding-left: 16px;
			padding-right: 16px;
		}

		&_active {
			display: grid;

			@include md {
				display: flex;
			}
		}
	}

	&__text {
		margin-bottom: 48px;

		@include xs {
			margin-bottom: 25px;
		}
	}

	&__tabs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 32px;
		border-radius: 16px;
		padding: 4px;
		gap: 4px;
		background: #fff;

		@include sm {
			margin-bottom: 17px;
		}
	}

	&__tab {
		@include btn-reset;

		display: flex;
		justify-content: center;
		border-radius: 12px;
		height: 48px;
		align-items: center;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #111;

		@include sm {
			font-size: 16px;
		}

		&_active {
			box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
			background: #272626;
			color: #fff;
		}
	}

	&__tab-dot {
		border-radius: 50%;
		width: 14px;
		aspect-ratio: 1;
		margin-right: 8px;
	}

	&__content-left {
		@include md {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	&__content-slider {
		display: none;

		@include md {
			display: flex;
			align-items: center;
			width: 265px;
			max-width: 100%;
			margin-bottom: 18px;
			border-bottom: 1px solid #F2F2F2;
		}

		.swiper {
			padding-bottom: 57px;
		}

		.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
			bottom: 20px;
		}

		.swiper-pagination-bullet {
			border-radius: 24px;
			width: 8px;
			height: 8px;
			background: #eceff4;
			opacity: 1;
			transition: width .2s;

			&-active {
				width: 31px;
				background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%), radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			}
		}
	}

	&__content-slider-item {
		display: flex;
		flex-direction: column;
		align-items: center;

	}

	&__service-prices {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__services {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
		margin-bottom: 40px;
		padding-top: 7px;

		@include md {
			display: none;
		}
	}

	&__service {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 12px;
		padding-right: 12px;
	}

	&__service-img {
		margin-bottom: -16px;
		width: 160px;
		aspect-ratio: 1;
		object-fit: contain;
		object-position: top center;
		font-size: 0;

		@include lg {
			margin-bottom: -27px;
			width: 120px;
		}

		@include sm {
			width: 160px;
		}
	}
	
	&__service-name {
		margin-bottom: 19px;
		border-radius: 8px;
		padding: 3px;
		font-weight: 400;
		font-size: 20px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;
		width: 100%;
		background-color: #fff;

		@include lg {
			margin-bottom: 25px;
			padding: 5px;
			font-size: 18px;
		}
	}

	&__service-price {
		margin-bottom: 32px;
		font-weight: 800;
		font-size: 32px;
		line-height: 1.1;
		text-align: center;
		color: #111;

		@include lg {
			font-size: 28px;
		}

		@include sm {
			font-size: 24px;
		}
	}

	&__service-pricer {
		
	}

	&__service-pricer-label {
		margin-bottom: 4px;
		font-style: italic;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: center;
		color: #1c1b14;
		
	}

	&__service-pricer-label-tooltip {
		margin-left: 8px;
		transform: translateY(5px);
	}

	&__service-pricer-value {
		font-weight: 800;
		font-size: 32px;
		line-height: 1.1;
		text-align: center;
		color: #2fb500;

		&_dark {
			color: #1c1b14;
		}

		@include lg {
			font-size: 28px;
		}

		@include sm {
			font-size: 24px;
		}
	}

	&__card {
		position: relative;
		border-radius: 24px;
		padding: 24px;
		width: 100%;
		background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
	
		@include xs {
			border-radius: 16px;
			padding: 17px 20px;
		}
	}

	&__card-price {
		margin-bottom: 2px;
		font-style: italic;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;
	}

	&__card-price-value {
		margin-bottom: 103px;
		font-weight: 800;
		font-size: 32px;
		line-height: 1.1;
		color: #111;

		@include xs {
			margin-bottom: 35px;
		}
	}

	&__card-list {
		@include list-reset;

		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 40px;

		@include sm {
			gap: 10px;
		}

		@include xs {
			margin-bottom: 30px;
		}
	}

	&__card-list-item {
		position: relative;
		padding-left: 36px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			padding-left: 24px;
			font-size: 15px;
			line-height: 19px;
			letter-spacing: normal;
		}
		
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			width: 24px;
			aspect-ratio: 1;
			background-color: #fff;
			background-image: url("../img/icon-check.svg");
			background-position: center center;
			background-size: 15px auto;
			background-repeat: no-repeat;

			@include sm {
				width: 16px;
				background-size: 10px auto;
			}
		}
	}

	&__card-btn {
		width: 100%;
	}

	&__card-img {
		position: absolute;
		right: -70px;
		top: -34px;
		width: 268px;
		aspect-ratio: 267/197;
		object-fit: contain;
		object-position: center;

		@include xs {
			right: -47px;
			top: -25px;
			width: 162px;
		}
	}
}