.banner {
	position: relative;
	z-index: 1;
	margin-bottom: 100px;
	overflow: hidden;
	border-radius: 0 0 64px 64px;
	background: #f1f4f9;
	
	@include lg {
		margin-bottom: 60px;
	}

	@include sm {
		margin-bottom: 48px;
		border-radius: 0;
	}

	&__container {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		padding-top: 51px;
		padding-bottom: 78px;
		min-height: 680px;

		@include lg {
			padding-top: 40px;
			padding-bottom: 60px;
			min-height: auto;
		}

		@include md {
			padding-bottom: 190px;
		}

		@include sm {
			padding-bottom: 312px;
		}

		@include xs {
			padding-top: 15px;
			padding-bottom: 241px;
		}

		&_mob-big-empty {
			@include xs {	
				padding-bottom: 459px;
			}
		}
	}

	&__bg {
		position: absolute;
		right: -78px;
		bottom: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-position: right center;
		background-size: auto 100%;
		background-repeat: no-repeat;

		@include lg {
			right: -150px;
		}

		@include md {
			right: -100px;
			bottom: -30px;
			background-position: right bottom;
			background-size: 900px auto;
		}

		@include sm {
			right: 0;
			bottom: 0;
			background-position: center bottom;
			background-size: 420px auto;
		}

		@include xs {
			background-size: 360px auto;
		}

		&_section {
			right: 0;
			background-position: right bottom;

			@include xl {
				background-size: 500px auto;
			}

			@include lg {
				background-size: 400px auto;
			}

			@include sm {
				background-position: right bottom;
				background-size: 360px auto;
			}

			@include xs {
				background-size: 360px auto;
				background-position: right bottom 85px;
			}
		}
	}

	&__content {
		width: 767px;
		max-width: 100%;

		@include lg {
			width: 700px;
		}
	}

	&__list {
		@include list-reset;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 7px;
		margin-bottom: 48px;

		@include xs {
			gap: 8px;
		}
	}

	&__list-item {
		position: relative;
		display: inline-block;
		border-radius: 12px;
		padding: 12.3px 24px;
		padding-left: 48px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		color: #111;
		background: #fff;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}

		@include xs {
			border-radius: 8px;
			width: 100%;
			padding: 6px 22px;
			padding-left: 31px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 12px;
			top: 12px;
			border-radius: 50%;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-check.svg"),  radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			background-position: center center;
			background-size: 16px 12px, 100%;
			background-repeat: no-repeat;

			@include xs {
				top: 9px;
				left: 9px;
				width: 15px;
				background-size: 10px auto, 100%;
			}
		}

		b {
			font-weight: 800;
		}
	}

	&__text {
		margin-bottom: 64px;
		// font-weight: 400;
		// font-size: 18px;
		// line-height: 1.3;
		// color: #111;
	}

	&__connect {
		display: grid;
		grid-template-columns: 457px 155px;
		grid-gap: 4px 18px;
		align-items: flex-start;

		@include lg {
			grid-template-columns: 400px 150px;
		}

		@include md {
			position: absolute;
			bottom: 20px;
			left: 0;
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			grid-template-columns: 100%;
		}
	}

	&__recall-mini-text {
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: center;
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.3;
		color: #111;

		b {
			position: relative;
			z-index: 1;
			font-weight: 400;
			
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				width: 100%;
				height: 5px;
				background: radial-gradient(128% 1677% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			}
		}
	}

	&__socials {
		@include list-reset;

		display: flex;
		gap: 4px;
	}

	&__socials-link {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 16px;
		width: 64px;
		aspect-ratio: 1;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include lg {
			width: 56px;
		}
	}

	&__socials-img {
		width: 40px;
	}
}