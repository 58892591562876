.subscription {
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 64px;
	padding-bottom: 32px;
	background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
		padding-top: 40px;
		padding-bottom: 35px;
	}

	&__content {
		position: relative;

		@include md {
			display: flex;
			flex-direction: column;
		}
	}

	&__text {
		margin-bottom: 48px;

		@include xs {
			margin-bottom: 36px;
		}
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
		margin-bottom: 32px;

		@include md {
			grid-template-columns: 100%;
			margin: 0 auto;
			width: 450px;
			max-width: 100%
		}

		@include xs {
			grid-gap: 30px;
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		border-radius: 20px;
		padding: 19px 24px 24px;
		box-shadow: 0 4px 32px 10px rgba(255, 255, 255, 0.02), 0 4px 32px 10px rgba(255, 255, 255, 0.02), 4px 4px 32px 10px rgba(255, 255, 255, 0.1);
		background: #fff;

		@include xs {
			padding: 15px 20px 20px;
		}
	}

	&__item-name {
		margin-bottom: 19px;
		font-style: italic;
		font-weight: 800;
		font-size: 24px;
		line-height: 1.1;
		color: #aeaeae;

		@include lg {
			margin-bottom: 10px;
		}

		@include xs {
			padding-right: 90px;
			margin-bottom: 12px;
		}
	}

	&__item-price {
		margin-bottom: 41px;
		font-weight: 800;
		font-size: 32px;
		line-height: 1.1;
		color: #111;

		@include lg {
			margin-bottom: 30px;
			font-size: 24px;
		}
	}

	&__item-icon  {
		position: absolute;
		right: -59px;
		top: -14px;
		width: 209px;
		aspect-ratio: 209/136;

		@include xl {
			right: -85px;
		}

		@include lg {
			right: -74px;
			width: 158px;
		}

		@include sm {
			right: -85px;
			width: 178px;
		}

		@include xs {
			top: -22px;
			right: -77px;
			width: 216px;
		}
	}

	&__item-advantages {
		@include list-reset;

		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 24px;
		padding-bottom: 27px;
		border-bottom: 1px solid #f2f2f2;

		@include lg {
			padding-bottom: 18px;
			margin-bottom: 18px;
			gap: 8px;
		}

		@include xs {
			margin-bottom: 16px;
			padding-bottom: 14px;
		}
	}

	&__item-advantage {
		position: relative;
		padding-left: 36px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		color: #111;

		@include lg {
			padding-left: 26px;
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			width: 24px;
			aspect-ratio: 1;
			background-image: url(../img/icon-check.svg), radial-gradient(1677.62% 128.37% at 46.55% 50%, #fde167 0, #fac60b 100%);
			background-position: center center;
			background-size: 16px 12px, 100%;
			background-repeat: no-repeat;

			@include lg {
				width: 20px;
				background-size: 12px auto, 100%;
			}

			@include sm {
				top: 1px;
				width: 16px;
				background-size: 10px 7px, 100%;
			}
		}

		b {
			font-weight: 400;
			text-decoration: underline;
		}
	}

	&__item-conditions {
		display: flex;
		flex-direction: column;
		gap: 13px;
		margin-bottom: 42px;

		@include lg {
			margin-bottom: 32px;
		}

		@include xs {
			gap: 8px;
			margin-bottom: 31px;
		}

		&_or {
			gap: 3px;

			@include xs {
				gap: 0px;
			}
		}
	}

	&__item-conditions-or {
		color: #8d8d8d;
		font-style: italic;
		font-size: 17px;

		@include lg {
			font-size: 15px;
		}
	}

	&__item-condition {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 12px;

		@include lg {
			gap: 8px;
		}
	}

	&__item-condition-icon {
		display: inline-block;
		width: 24px;
		aspect-ratio: 1;

		@include lg {
			width: 20px;
		}

		@include sm {
			width: 16px;
		}
	}

	&__item-invite {
		width: 100%;
		margin-top: auto;
	}

	&__btn {
		position: absolute;
		right: 0px;
		top: 0px;
		width: 295px;

		@include md {
			position: relative;
			right: 0;
			top: 0;
			width: 280px;
			margin: 0 auto;
			margin-top: 20px;
			margin-bottom: 8px;
		}
	}

	&__item-condition-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}
	}

	&__sub-text {
		margin-bottom: 24px;
		font-weight: 700;
		font-size: 32px;
		line-height: 1.1;
		color: #111;

		@include sm {
			margin-bottom: 19px;
			font-size: 20px;
		}
	}

	// &__more {
	// }
}