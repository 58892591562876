* {
	overflow-anchor: none;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*::after,
*::before {
	box-sizing: border-box;
}

// .slick-loading .slick-list {
// 	background: #fff;
// }

a {
	text-decoration: none;
}

::placeholder {
	color: #666;
}

::selection {
	text-shadow: none;
	color: #fff;
	background-color: #3390ff;
}

b,
strong {
	font-weight: 700;
}

ul.rst,
ol.rst {
	list-style: none;
}

input,
textarea {
	border: #666 1px solid;
	font-family: $default-font;

	// outline: none;
	// &:focus:required:invalid {
	// 	border-color: red;
	// }
	// &:required:valid {
	// 	border-color: green;
	// }
}

body {
	width: 100%;
	height: 100%;
	min-width: 360px;
	font-style: normal;
	line-height: 1.15;
}

[hidden] {
	display: none;
}

textarea {
	vertical-align: top;
	overflow: auto;
	resize: vertical;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
}

button[disabled],
input[disabled] {
	cursor: default;
}

sub,
sup {
	position: relative;
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

button {
	font-family: inherit;
	font-weight: inherit;
}