.custom-select {
	.choices__inner {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		border: 1px solid rgba(17, 17, 17, 0.4);
		border-radius: 16px;
		min-height: 56px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		color: #777;
		background-color: #fff;

		.choices__item  {
			position: absolute;
			inset: 0;
			margin: 0;
			border: none;
			border-radius: 0;
			padding: 17px 24px;
			padding-right: 30px;
			background-color: #fff;

			@include lg {
				padding: 18px 24px;
				padding-right: 30px;
				font-size: 16px;
				line-height: 19px;
			}
		}
	}

	.choices__placeholder {
		opacity: 1;
	}

	.choices__list--single {
		padding-right: 30px;
		padding-left: 18px;
	}

	.choices[data-type*=select-one]::after {
		top: 14px;
		right: 14px;
		margin-top: 0;
		aspect-ratio: 1;
		border: none;
		width: 26px;
		height: auto;
		background-image: url("../img/icon-down-thin.svg");
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.choices__list--dropdown {
		z-index: 2;
		margin-top: 2px;
		border-radius: 16px;
		border: none;
		box-shadow: 0 4px 32px 3px rgba(0, 0, 0, 0.05);
		background: #fff;
	}

	.choices__item {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		color: #111;
	}
	
	.simplebar-scrollbar {
		z-index: 2;
	}

	.simplebar-track.simplebar-vertical {
		border-radius: 2px;
		right: 8px;
		top: 8px;
		bottom: 8px;
		width: 8px;
		background: #f1f4f9;
	}

	.simplebar-scrollbar:before {
		top: 1px;
		bottom: 1px;
		left: 1px;
		right: 1px;
	}

	.simplebar-scrollbar.simplebar-visible:before {
		border-radius: 1px;
		opacity: 1;
		background: #ccc;
	}

	.choices__input {
		background-color: transparent;
	}

	.choices__list--dropdown  .choices__placeholder {
		display: none;
	} 
}