.services {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@include xs {
			gap: 16px;
		}
	}

	&__item {
		position: relative;
		z-index: 1;
		overflow: hidden;
		border-radius: 32px;
		padding: 15px 32px;
		padding-right: 492px;
		min-height: 394px;
		box-shadow: 0 4px 32px 3px rgba(0, 0, 0, 0.05);
		background: #fff;

		@include xl {
			padding-right: 365px;
		}

		@include lg {
			padding-right: 280px;
		}

		@include md {
			padding-right: 32px;
		}

		@include xs {
			padding: 15px 20px;
			border-radius: 24px;
		}
	}

	&__img {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: -1;
		height: 100%;
		width: auto;

		@include xl {
			height: auto;
			width: 380px;
			object-position: right bottom
		}

		@include lg {
			width: 280px;
		}

		@include md {
			top: 0;
			bottom: auto;
			aspect-ratio: 280/180;
		}

		@include sm {
			position: relative;
			margin-bottom: 0px;
			width: 100%;
		}
	}

	&__item-name {
		position: relative;
		display: inline-block;
		margin-bottom: 14px;
		padding-right: 32px;
		font-weight: 700;
		font-size: 32px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 28px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 10px;
			font-size: 24px;
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 12px;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-down-thin.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			transform: rotate(-90deg);

			@include lg {
				top: 6px;
			}

			@include sm {
				top: 3px;
			}
		}
	}

	&__item-text {
		margin-bottom: 32px;
		width: 371px;
		max-width: 100%;
		min-height: 50px;

		@include lg {
			min-height: auto;
		}

		@include md {
			margin-bottom: 100px;
		}

		@include sm {
			margin-bottom: 16px;
		}
	}

	&__item-orders {
		display: flex;
		gap: 8px;

		@include xs {
			flex-direction: column;
		}
	}

	&__item-order {
		display: flex;
		flex-direction: column;
		border-radius: 24px;
		padding: 20px 24px 24px; 
		width: 354px;
		height: 208px;
		backdrop-filter: blur(16px);
		background: #f7f8fb;

		@include xs {
			padding: 0;
			width: 100%;
			height: auto;
			gap: 16px;
			background-color: transparent;
		}
	}

	&__item-price {
		font-weight: 800;
		font-size: 26px;
		line-height: 1.3;
		color: #242424;

		@include lg {
			font-size: 20px;
			line-height: 1.1;
		}
	}

	&__item-order-btn {
		margin-top: auto;
	}

	&__item-order-card {
		display: flex;
		flex-direction: column;
		border: 1.50px solid #FAC60B;
		border-radius: 24px;
		padding: 20px 24px 24px; 
		width: 354px;
		height: 208px;
		backdrop-filter: blur(16px);
		background: #fff;

		@include xs {
			border-radius: 16px;
			padding: 16px 18px 24px; 
			width: 100%;
			height: auto;
		}
	}

	&__item-order-card-price {
		margin-bottom: 4px;
		font-weight: 700;
		font-size: 26px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 20px;
			line-height: 1.1;
		}
	}

	&__item-order-card-price-only {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 14px;
		}

		@include xs {
			margin-bottom: 30px;
		}
	}
}