.container {
	margin: 0 auto;
	padding-right: 20px;
	padding-left: 20px;
	width: 100%;
	max-width: 1280px;

	@include xl {
		max-width: 1180px;
	}

	@include lg {
		max-width: 960px;
	}

	@include md {
		max-width: 720px;
	}

	@include sm {
		max-width: 549px;
		// padding-left: 16px;
		// padding-right: 16px;
	}

	@include xs {
		max-width: 100%;
	}
}


