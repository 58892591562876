.survey {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__form {
		margin: 0 auto;
		border-radius: 32px;
		padding: 42px 48px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);

		@include sm {
			padding: 27px 20px;
		}
	}

	&__block-title {
		font-weight: 800;
		font-size: 32px;
		line-height: 1.1;
		color: #111;

		@include sm {
			font-size: 20px;
			font-weight: 700;
		}

		&_big-offset {
			margin-bottom: 40px;

			@include sm {
				margin-bottom: 20px;
			}
		}

		&_small-offset {
			margin-bottom: 16px;
		}

		&_offset {
			margin-bottom: 32px;

			@include sm {
				margin-bottom: 17px;
			}
		}
	}

	&__block-text {
		margin-bottom: 35px;
		max-width: 704px;

		@include sm {
			margin-bottom: 22px;
		}
	}

	&__radios {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;

		@include sm {
			grid-template-columns: 100%;
			grid-gap: 5px;
		}
	}

	&__radio {
		width: 100%;
	}

	&__block {
		&:not(:last-child) {
			margin-bottom: 48px;

			@include sm {
				margin-bottom: 27px;
			}
		}
	}

	&__fields {
		display: flex;
		flex-wrap: wrap;
		gap: 12px 20px;

		@include sm {
			gap: 10px;
		}
	}

	&__field {
		&_fio {
			width: 579px;

			@include xl {
				width: 479px;
			}

			@include md {
				width: 100%;
			}
		}

		&_fio-ren {
			width: 579px;

			@include xl {
				width: 479px;
			}

			@include lg {
				width: 259px
			}

			@include md {
				width: 100%;
			}
		}

		&_phone {
			width: 215px;

			@include sm {
				width: 100%;
			}
		}

		&_email {
			width: 310px;

			@include md {
				width: 349px;
			}

			@include sm {
				width: 100%;
			}
		}

		&_mark {
			width: 349px;

			@include xl {
				width: 330px;
			}

			@include md {
				width: 282px;
			}

			@include sm {
				width: 100%;
			}
		}

		&_model {
			width: 348px;

			@include xl {
				width: 285px;
			}

			@include md {
				width: 282px;
			}

			@include sm {
				width: 100%;
			}
		}

		&_year {
			width: 172px;

			@include xl {
				width: 154px;
			}

			@include sm {
				width: 100%;
			}
		}

		&_number {
			width: 215px;

			@include md {
				width: 410px;
			}

			@include sm {
				width: 100%;
			}
		}

		&_manager {
			margin-top: 40px;
			width: 579px;

			@include xl {
				width: 479px;
			}

			@include md {
				width: 100%;
			}

			@include sm {
				width: 100%;
			}
		}

		&_hidden {
			display: none;
		}
	}

	&__bottom {
		border-top: 1px solid #F2F2F2;
		margin-top: 52px;
		padding-top: 48px;

		@include sm {
			margin-top: 28px;
			padding-top: 32px;
		}
	}

	&__rules {
		margin-bottom: 24px;

		@include sm {
			margin-bottom: 20px;
		}
	}

	&__submit {
		margin-bottom: 16px;
		width: 100%;
	}

	&__agree {
		font-weight: 400;
		font-size: 16px;

		// line-height: 18px; 
		line-height: 1.1;
		text-align: center;
		color: #111;

		@include sm {
			font-size: 15px;
		}

		a {
			color: inherit;
			text-decoration: underline;
			text-decoration-skip-ink: none;
		}
	}
}