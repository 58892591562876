.prices {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;

		@include md {
			grid-template-columns: 100%;
		}
	}

	&__item {
		border: 1px solid rgba(17, 17, 17, 0.1);
		border-radius: 24px;
		padding: 32px 24px 24px 24px;
		background: #fff;

		@include xs {
			padding: 20px;
		}

		&_gold {
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		}
	}

	&__item-name {
		margin-bottom: 36px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			margin-bottom: 9px;
			font-size: 20px;
		}
	}

	&__item-list {
		@include list-reset;

		margin-bottom: 30px;

		@include xs {
			margin-bottom: 22px;
		}
	}

	&__item-list-item {
		display: flex;
		justify-content: space-between;
		padding-top: 14px;
		padding-bottom: 14px;

		@include xs {
			flex-direction: column;
			padding-top: 12px;
			padding-bottom: 12px;
		}


		&:not(:last-child) {
			border-bottom: 1px solid #E5E5E5;
		}
	}

	&__item-list-item-label {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include xs {
			margin-bottom: 3px;
			font-size: 16px;
		}
	}

	&__item-list-item-value {
		font-weight: 700;
		font-size: 20px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		text-align: right;
		color: #111;

		@include xs {
			font-size: 18px;
			text-align: left;
		}
	}

	&__btn {
		width: 100%;
	}
}