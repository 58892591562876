.fixed-bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 2;
	padding: 16px 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	border-radius: 32px 32px 0 0;
	box-shadow: 0 -5px 32px 10px rgba(0, 0, 0, 0.2);
	background: #fff;
	visibility: hidden;
	transform: translateY(100%);
	transition:
		visibility .2s,
		transform .2s;

	&__container {
		@include xs {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__recall {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
	}

	&_active {
		@include md {
			visibility: visible;
			transform: translateY(0);
		}
	}
}

.open-menu {
	.fixed-bottom {
		box-shadow: none;
	}
}