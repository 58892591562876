.custom-field {
	position: relative;

	&__label {
		margin-bottom: 6px;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px; 
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}

		&_small {
			font-weight: 400;
			font-size: 16px;
			line-height: 18px; 
			color: #111;

			@include sm {
				font-size: 15px;
				line-height: 1.3;
			}
		}
	}

	&__input {
		width: 100%;
	}

	// &__mess {
	// 	margin-top: 9px;
	// 	font-style: italic;
	// 	font-weight: 400;
	// 	font-size: 14px;
	// 	line-height: 1.3;
	// 	color: #111;

	// 	&_absolute {
	// 		position: absolute;
	// 		top: 100%;
	// 		left: 0;
	// 	}
	// }
}