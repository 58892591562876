.faq {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__items {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-bottom: 24px;

		@include xs {
			margin-bottom: 14px;
		}
	}

	&__item {
		border-radius: 16px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		&_active {
			.faq {
				&__item-name {
					&::after {
						transform: rotate(180deg);

						@include xs {
							transform: translateY(-50%) rotate(180deg);
						}
					}
				}

				&__item-content {
					max-height: none;
				}
			}
		}
	}

	&__item-name {
		position: relative;
		padding: 20px 120px 25px 24px;
		font-weight: 700;
		font-size: 20px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;
		cursor: pointer;

		@include lg {
			font-size: 18px;
		}

		@include sm {
			font-size: 15px;
		}

		@include xs {
			padding: 20px 50px 20px 20px;
			
		}

		&::after {
			content: "";
			position: absolute;
			right: 20px;
			top: 20px;
			width: 32px;
			aspect-ratio: 1;
			background-image: url("../img/icon-down-thin.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			transition: transform .2s;

			@include xs {
				top: 50%;
				width: 24px;
				transform: translateY(-50%);
			}
		}
	}

	&__item-content {
		overflow: hidden;
		max-height: 0;
		transition: 
			max-height .3s linear;
	}

	&__item-inner {
		padding: 0 24px 20px;

		@include xs {
			padding: 0 20px 20px;
		}
	}

	&__support {
		display: flex;
		border-radius: 24px;
		padding: 28px;
		padding-right: 24px;
		padding-left: 290px;
		height: 151px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02), 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background-image: url("../img/upload/faq-support.png"), radial-gradient(128.62% 1677.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		background-position: left center, center;
		background-size: contain, 100%;
		background-repeat: no-repeat;

		@include lg {
			padding-left: 130px;
			background-image: url("../img/upload/faq-support-m.png"), radial-gradient(128.62% 1677.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
			background-position: left 20px center, center;
			background-size: 92px, 100%;
		}

		@include md {
			padding: 20px;
			height: auto;
			flex-direction: column;
			background-position: right 20px top 20px, center;
		}
	}

	&__support-info {
		margin-right: auto;
		max-width: 392px;

		@include md {
			margin-right: 0;
			margin-bottom: 26px;
			padding-right: 117px;
		}

		@include xs {
			margin-bottom: 12px;
			padding-right: 107px;
		}
	}

	&__support-title {
		margin-bottom: 14px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 18px;
		}

		@include xs {
			margin-bottom: 12px;
		}
	}

	&__support-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 1.3;
		}

		b {
			font-weight: 700;
		}
	}

	&__support-contacts {
		display: flex;
		align-items: center;
		gap: 8px;

		@include xs {
			width: 100%;
			justify-content: space-between;
		}
	}

	&__support-phone {
		position: relative;
		border-radius: 16px;
		padding: 16px 47px 16px 80px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		text-align: center;
		color: #000;
		white-space: nowrap;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;
		transition:  box-shadow .2s;

		@include lg {
			padding: 16px 30px 16px 70px;
		}

		@include xs {
			padding: 0;
			padding-left: 27px;
			text-align: left;
			font-size: 16px;
			line-height: 1.2;
			background-color: transparent;
		}

		&::before {
			content: "";
			position: absolute;
			left: 4px;
			top: 4px;
			border-radius: 12px;
			width: 48px;
			aspect-ratio: 1;
			background: #f7f7f7;
			background-image: url("../img/icon-phone-black.svg");
			background-position: center center;
			background-size: 24px;
			background-repeat: no-repeat;

			@include xs {
				left: 0;
				top: -2px;
				width: 24px;
				background-color: transparent;
				background-size: contain;
			}
		}

		@include hover {
			box-shadow: none;
		}
	}

	&__socials {
		@include list-reset;

		display: flex;
		gap: 4px;

		@include xs {
			gap: 8px;
		}
	}
	
	&__socials-item {
		border-radius: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 56px;
		aspect-ratio: 1;
		font-size: 0;
		box-shadow: 0 3px 28px 9px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			border-radius: 12px;
			width: 40px;
		}
	}

	&__socials-img {
		width: 35px;
		aspect-ratio: 1;

		@include xs {
			width: 24px;
		}
	}
}