.only-order {
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 126px;
	padding-bottom: 126px;
	background-image: url("../img/upload/only-order.jpg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
		padding-top: 80px;
		padding-bottom: 85px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 55px;
		padding-top: 45px;
		padding-bottom: 48px;
	}

	&__form {
		margin: 0 auto;
		border: 1px solid #eee;
		border-radius: 32px;
		padding: 24px 40px 12px;
		width: 1004px;
		max-width: 100%;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			border: none;
			padding: 0;
			box-shadow: 0;
			background-color: transparent;
		}
	}

	&__label {
		@include xs {
			color: #fff;
			text-align: center;
		}
	}

	&__fields {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		width: 100%;
		align-items: flex-end;

		@include sm {
			grid-template-columns: 100%;
			gap: 8px;
		}
	}

	&__mess {
		margin-top: 9px;
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.3;
		color: #111;

		@include sm {
			text-align: center
		}

		@include xs {
			color: #fff;
		}
	}
}