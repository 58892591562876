.rules {
	margin-top: 51px;
	margin-bottom: 100px;

	@include sm {
		margin-top: 16px;
		margin-bottom: 47px;
	}

	&__content {
		border-radius: 32px;
		padding: 32px 40px;
		box-shadow: 0 4px 32px 3px rgba(0, 0, 0, 0.05);
		background: #fff;
		counter-reset: rule;

		@include sm {
			border-radius: 16px;
			padding: 32px 16px;
		}
	}

	&__title {
		margin-bottom: 10px;
		text-align: center;

		@include xs {
			text-align: left;
		}
	}

	&__edited {
		margin-bottom: 36px;
		text-align: center;
		
		@include xs {
			margin-bottom: 46px;
			text-align: left;
		}
	}

	&__rule {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}

	&__rule-name {
		position: relative;
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		padding-left: 93px;
		min-height: 69px;
		font-weight: 700;
		font-size: 32px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			padding-left: 80px;
			min-height: 54px;
			font-size: 24px;
		}

		@include sm {
			min-height: 48px;
			padding-left: 65px;
			font-size: 20px;
			line-height: 1.2;
		}

		&::before {
			counter-increment: rule;
			content: counter(rule);
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			aspect-ratio: 1;
			border-radius: 100%;
			width: 69px;
			font-weight: 700;
			font-size: 24px;
			line-height: 1.1;
			text-align: center;
			color: #111;
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

			@include lg {
				width: 54px;
			}

			@include sm {
				width: 48px;
			}
		}
	}

	&__rule-content	{
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 19px;
			letter-spacing: normal;
		}

		small {
			font-weight: 300;
			color: #808080;
		}

		h4 {
			margin-bottom: 18px;
			font-weight: 700;
			font-size: 24px;
			line-height: 1.4;
			letter-spacing: 0.01em;
			color: #000;

			@include lg {
				font-size: 22px;
				line-height: 1.1;
			}
	
			@include sm {
				font-size: 18px;
			}
		}

		ul {
			@include list-reset;

			// list-style-position: inside;
		}

		p,
		ul {
			&:not(:last-child) {
				margin-bottom: 23px;
			}
		}

		li {
			position: relative;
			padding-left: 28px;
			font-weight: 300;

			@include sm {
				padding-left: 22px;
			}

			&::before {
				content: "";
				position: absolute;
				left: 12px;
				top: 12px;
				width: 4px;
				aspect-ratio: 1;
				border-radius: 50%;
				background-color: #111;

				@include lg {
					top: 10px;
				}

				@include sm {
					top: 9px;
					left: 10px;
				}
			}

			&:not(:last-child) {
				margin-bottom: 15px;

				@include sm {
					margin-bottom: 17px;
				}
			}
		}
	}

	&__author {
		margin-top: 47px;
		font-weight: 700;
		font-size: 18px;
		text-align: right;
		color: #000;
	}
}