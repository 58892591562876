.fixed-tow-bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 2;
	padding: 16px 0;
	width: 100%;
	display: none;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	border-radius: 32px 32px 0 0;
	box-shadow: 0 -5px 32px 10px rgba(0, 0, 0, 0.2);
	background: #fff;

	@include md {
		display: block;
	}

	&__container {
		@include xs {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&__content {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 12px;
		width: 100%;

		@include sm {
			flex-direction: column;
			width: 328px;
			gap: 8px;
		}
	}

	&__recall {
		@include sm {
			width: 100%;
		}
	}

	&__socials {
		@include list-reset;

		display: flex;
		align-items: center;
		font-size: 0;
		gap: 3px;
	}

	&__socials-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		aspect-ratio: 1;
		font-size: 0;
	}

	&__socials-img {
		width: 32px;
		aspect-ratio: 1;
	}

	&__status {
		position: relative;
		display: none;
		margin-right: auto;
		margin-bottom: 8px;
		align-self: flex-start;
		width: 100%;
		padding-left: 26px;
		font-weight: 400;
		font-size: 15px;
		color: #000;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			aspect-ratio: 1;
			border-radius: 50%;
			border-color: #C3F0B4;
			border-width: 5px;
			border-style: solid;
			background-color: #37cb03;
		}
	}
}


.open-menu {
	.fixed-tow-bottom {
		box-shadow: none;

		&__status {

			@include md {
				display: block;
			}
		}
	}
}