// font-weight helper:

// 100 Thin hairline
// 200 Extra|ultra light
// 300 Light
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Extra Bold or Ultra Bold;
// 900 Extra Black or Fat or Ultra Black or Heavy

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $alternate: "") {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-display: swap;

		@if $style != "" {
			font-style: $style;
		} @else {
			font-style: normal;
		}

		@if $alternate != "" {
			src:
				local("#{$font-family}"),
				local("#{$alternate}"),
				url("#{$file-path}.woff2") format("woff2");
		} @else {
			src:
				local("#{$font-family}"),
				url("#{$file-path}.woff2") format("woff2");
		}
	}
}

@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Regular", 400, "", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Italic", 400, "italic", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Semibold", 600, "", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-SemiboldItalic", 600, "italic", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Bold", 700, "", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Ultrabold", 800, "", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-UltraboldItalic", 800, "italic", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-Black", 900, "", "");
@include font-face("RFDewi", "../fonts/RFDewi/RFDewi-BlackItalic", 900, "italic", "");