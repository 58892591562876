@mixin list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin btn-reset {
	display: inline-block;
	border: none;
	text-align: center;
	cursor: pointer;
	background-color: transparent;
}

@mixin visually-hidden {
	position: fixed;
	transform: scale(0);
}

@mixin table-reset {
	border-collapse: collapse;
	border-spacing: 0;
}

@mixin hover {
	@media (any-hover: hover) {
		&:hover {
			@content;
		}
	}
}