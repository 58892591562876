.order {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__content {
		border: 1px solid #eee;
		border-radius: 32px;
		padding: 40px 24px;
		background-color: #fff;

		@include xs {
			padding: 32px 16px 24px;
		}

		&_bottom-small {
			padding-bottom: 24px;
		}
	}

	&__form {
		display: grid;
		grid-template-columns: auto 434px;
		grid-gap: 20px;
		align-items: flex-end;

		@include lg {
			grid-template-columns: auto 327px;
		}

		@include md {
			grid-template-columns: 100%;
		}

		@include xs {
			grid-gap: 23px;
		}

		&:not(:last-child) {
			margin-bottom: 40px;

			@include xs {
				margin-bottom: 30px;
			}
		}
	}

	&__form-fields {
		display: grid;
		grid-template-columns: auto 279px;;
		grid-gap: 20px;

		@include lg {
			grid-template-columns: auto 205px;;
		}

		@include xs {
			grid-template-columns: 100%;
			grid-gap: 10px;
		}
	}

	&__form-send  {
		padding-left: 10px;
		padding-right: 10px;
	}

	&__form-title {
		margin-bottom: 37px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 18px;
		}

		@include xs {
			margin-bottom: 20px;
		}
	}

	&__text {
		position: relative;
		border-radius: 24px;
		padding: 40px 24px 15px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #1c1b14;
		background: #f1f4f9;

		@include xl {
			padding-top: 60px;
		}

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
			line-height: 21px;
			letter-spacing: normal;
		}

		@include xs {
			border-radius: 12px;
			padding: 34px 20px 15px;
		}

		p {
			&:not(:last-child) {
				margin-bottom: 25px;

				@include xs {
					margin-bottom: 20px;
				}
			}
		}
	}

	&__tooltip {
		position: absolute;
		right: 23px;
		top: 23px;

		@include xs {
			right: 7px;
			top: 12px;
		}
	}
}