.header {
	position: relative;
	z-index: 5;

	@include md {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		background-color: #fff;
	}


	&__top {
		position: relative;
		z-index: 2;
		background-color: #fff;
	}

	&__top-container {
		position: relative;
		display: flex;
		align-items: center;
		padding-top: 12px;
		padding-bottom: 8px;

		@include xs {
			padding-left: 16px;
			padding-right: 16px;
			padding-top: 7px;
			padding-bottom: 7px;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 20px;
			width: calc(100% - 40px);
			height: 0;
			border-bottom: 1px solid #ccc;

			@include md {
				display: none;
			}
		}
	}

	&__status {
		position: relative;
		margin-right: 16px;
		padding-left: 26px;
		font-weight: 400;
		font-size: 15px;
		color: #000;

		@include md {
			display: none;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			aspect-ratio: 1;
			border-radius: 50%;
			border-color: #C3F0B4;
			border-width: 5px;
			border-style: solid;
			background-color: #37cb03;
		}
	}


	&__logo {
		margin-right: auto;
		width: 241px;
		aspect-ratio: 241/56;

		@include sm {
			width: 180px;
		}
	}

	&__bottom {
		@include md {
			display: none;
		}
	}

	&__bottom {
		position: relative;
		z-index: 2;
		background-color: #fff;
	}

	&__bottom-container {
		display: flex;
		align-items: center;
	}

	&__order {
		margin-right: 24px;

		@include sm {
			display: none;
		}
	}

	&__socials {
		@include list-reset;

		display: flex;
		align-items: center;
		font-size: 0;

		@include md {
			margin-right: 8px;
		}
	}

	&__social-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		aspect-ratio: 1;

		@include sm {
			width: 40px;
		}
	}

	&__social-svg {
		width: 32px;
		aspect-ratio: 1;

		@include xs {
			width: 28px;
		}
	}

	&__menu {
		margin-right: auto;
	}

	&__menu-list {
		@include list-reset;

		display: flex;
		gap: 32px;

		@include lg {
			gap: 12px;
		}
	}

	&__menu-item {
		position: relative;

		&_has-child {
			padding-right: 17px;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 24px;
				width: 12px;
				aspect-ratio: 1;
				background-image: url("../img/icon-down.svg");
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				cursor: pointer;
				transition: transform .2s;

				@include lg {
					top: 22px;
				}
			}
		}

		&_child-open {
			&::after {
				content: "";
				transform: rotate(180deg);
			}
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			display: none;
			border-radius: 1px 1px 0 0;
			width: 100%;
			height: 2px;
			background: #fbd233;
		}

		&_active {
			&::before {
				display: block;
			}
		}
	}

	&__menu-link {
		display: inline-block;
		padding-top: 17px;
		padding-bottom: 19px;
		font-weight: 700;
		font-size: 16px;
		color: #000;

		@include lg {
			font-size: 14px;
		}
	}

	&__child-menu {
		position: absolute;
		left: 0;
		// z-index: -1;
		top: 100%;
		visibility: hidden;
		border-top: 0.50px solid #ccc;
		width: 100%;
		transition: visibility .2s;

		@include md {
			display: none;
		}

		&_active {
			visibility: visible;

			.header__child-menu-overlay {
				opacity: 1;
			}

			.header__child-menu-content {
				transform: translateY(0);
			}
		}
	}

	&__child-menu-content {
		transform: translateY(-100%);
		transition: transform .2s;
		background-color: #fff;
	}

	&__child-menu-container {
		position: relative;
		z-index: 1;
		display: flex;
		gap: 114px;
		padding-top: 28px;
		padding-bottom: 44px;

		@include xl {
			gap: 100px;
		}

		@include lg {
			gap: 50px;
		}
	}

	&__child-menu-col-list {
		@include list-reset;

		display: flex;
		flex-direction: column;
		gap: 14px;

		@include lg {
			gap: 8px;
		}
	}

	&__child-menu-col-list-link {
		font-weight: 400;
		font-size: 16px;
		color: #000;

		@include lg {
			font-size: 15px;
		}
	}

	&__child-menu-overlay {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		opacity: 0;
		width: 100%;
		height: calc(100vh - 137px);
		background: rgba(0, 0, 0, 0.4);
		transition: opacity .2s;
	}

	&__phone {
		position: relative;
		padding-left: 36px;
		font-weight: 700;
		font-size: 20px;
		color: #000;

		@include lg {
			padding-left: 26px;
			font-size: 16px;
		}

		@include md {
			margin-right: 8px;
			border-radius: 8px;
			width: 40px;
			aspect-ratio: 1;
			background: #f7f7f7;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 2px;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-phone.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;

			@include lg {
				width: 20px;
			}

			@include md {
				left: 50%;
				top: 50%;
				width: 24px;
				background-image: url("../img/icon-phone-black.svg");
				transform: translate(-50%, -50%);
			}
		}
	}

	&__burger {

	}
}