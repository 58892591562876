.partners-gl {
	margin-top: 51px;
	margin-bottom: 100px;

	@include sm {
		margin-top: 16px;
		margin-bottom: 58px;
	}

	&__title {
		margin-bottom: 64px;
	}

	&__block-title {
		margin-bottom: 48px;
		font-weight: 800;
		font-size: 40px;
		line-height: 1.1;
		color: #111;

		@include lg {
			margin-bottom: 35px;
			font-size: 36px;
		}

		@include sm {
			font-size: 24px;
		}

		@include xs {
			margin-bottom: 24px;
		}
	}

	&__block {
		&:not(:last-child) {
			margin-bottom: 94px;

			@include xs {
				margin-bottom: 91px;
			}
		}
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;

		@include md {
			grid-template-columns: repeat(2, 1fr);
		}

		@include xs {
			grid-template-columns: 100%;
			grid-gap: 28px;
		}
	}

	&__item-img {
		margin-bottom: 11px;
		border-radius: 23px;
		box-shadow: 0 3px 26px 8px rgba(0, 0, 0, 0.02);
		width: 260px;
		aspect-ratio: 260/107;
		background: #fff;
		object-fit: contain;
		object-position: center;

		@include xs {
			margin-bottom: 7px;
			width: 180px;
		}
	}

	&__item-name-link {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		gap: 12px;

		@include xs {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}

		&_big-offset {
			margin-bottom: 24px;
		}
	}

	&__item-name {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #111;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			font-size: 18px;
		}
	}

	&__item-link {
		display: inline-block;
		font-weight: 600;
		font-size: 16px;
		line-height: 1.1;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		color: #111;
	}

	&__text {

	}
}