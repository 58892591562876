.quiz {
	margin-top: 100px;
	margin-bottom: 100px;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
	}

	&__group-title {
		margin-bottom: 24px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
		}

		@include sm {
			margin-bottom: 21px;
			letter-spacing: normal;
			font-size: 18px;
			line-height: 1.1;
		}
	}

	&__districts {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 8px;
		// width: 610px;

		@include md {
			width: 100%;
		}
	}

	&__district {
		width: 100%;
		grid-column: span 2;

		&_double {
			grid-column: span 3;
		}

		&_triple {
			grid-column: span 6;
		}
	}

	&__place {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, minmax(100px, 1fr));
		grid-gap: 20px;
		margin-bottom: 39px;

		@include md {
			grid-template-columns: 100%;
			margin-bottom: 50px;
		}

		@include xs {
			margin-bottom: 28px;
		}
	}

	&__place-left {
		@include md {
			order: 2;
		}
	}

	&__place-map {
		position: absolute;
		right: 0;
		top: 0;
		width: calc(50% - 10px);
		height: 100%;
		border-radius: 20px;
		overflow: hidden;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);

		@include md {
			position: relative;
			width: 100%;
			height: auto;
			aspect-ratio: 328/192;
			margin-bottom: 24px;
			// display: none;
		}
	}

	&__details {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 32px 20px;
		margin-bottom: 62px;

		@include sm {
			grid-template-columns: 100%;
		}
	}

	&__group-type {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 8px;
	}

	&__group-circumstances {
		display: grid;
		grid-gap: 8px;
	}

	&__group-wheels {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 8px;
	}

	&__group-extra {
		display: grid;
		grid-gap: 8px;
	}

	&__form-controls-container {
		margin: 0 auto;
		width: 1004px;
		max-width: 100%;
	}

	&__form-controls-wrapper {
		width: 100%;
	}

	&__form-controls {
		z-index: 3;
		padding: 20px 40px 14px;
		border: 1px solid #eee;
		border-radius: 32px;
		width: 100%;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include sm {
			padding: 16px;
			padding-bottom: 20px;
		}
	}

	&__form-label {
		@include md {
			text-align: center;
		}
	}

	&__form-fields {
		display: grid;
		align-items: flex-end;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;

		@include sm {
			grid-template-columns: 100%;
			grid-gap: 8px;
		}
	}

	&__form-mess {
		margin-top: 9px;
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.3;
		color: #111;

		@include sm {
			text-align: center
		}
	}

	&__go-map {
		@include btn-reset;

		position: relative;
		margin-bottom: 45px;
		padding-bottom: 2px;
		font-weight: 600;
		font-size: 15px;
		line-height: 1.3;
		color: #111;
		background-color: transparent;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background: radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
		}
	}

	&__text-block {
		margin-bottom: 64px;
		width: 100%;
		max-width: 901px;
	}

	&__area {
		margin-bottom: 24px;
		display: none;

		&_visible {
			display: block;
		}
	}

	&__area-items {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 8px;

		@include md {
			grid-template-columns: repeat(3, 1fr);
		}

		@include sm {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__area-item {
		&_many-words {
			@include xs {
				grid-column: span 2;
			}
		}
	}


	&__exact {
		margin-bottom: 70px;
		display: none;

		@include sm {
			margin-bottom: 40px;
		}

		&_visible {
			display: block;
		}

		&_active {
			.quiz__exact-btn {
				&::after {
					transform: rotate(180deg);
				}
			}

			.quiz__exact-dropdown {
				max-height: none;
			}
		}
	}

	&__exact-dropdown {
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;
	}

	&__exact-btn {
		@include btn-reset;

		position: relative;
		margin-bottom: 10px;
		padding-left: 32px;
		padding-right: 32px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.3;
		text-transform: uppercase;
		color: #111;

		@include xs {
			margin-bottom: 17px;
			font-size: 16px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-pin-dark.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 24px;
			aspect-ratio: 1;
			background-image: url("../img/icon-down-thin.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			transition: transform .2s;
		}
	}

	&__exact-form {
		border: 1px solid #eee;
		border-radius: 32px;
		padding: 26px 40px;
		display: grid;
		grid-template-columns: auto 357px;
		grid-gap: 20px;
		align-items: flex-end;
		background-color: #fff;

		@include lg {
			grid-template-columns: auto 327px;
		}

		@include md {
			grid-template-columns: 100%;
		}

		@include xs {
			padding: 9px 16px 24px;
			grid-gap: 23px;
		}
	}

	&__exact-form-fields {
		display: grid;
		grid-template-columns: auto 275px;;
		grid-gap: 20px;

		@include lg {
			grid-template-columns: auto 205px;;
		}

		@include xs {
			grid-template-columns: 100%;
			grid-gap: 10px;
		}
	}

	&__form-choiced-area-info {
		display: none;
		align-items: center;
		margin-bottom: 15px;
		border-bottom: 1px solid #EEEEEE;
		padding-bottom: 18px;

		@include md {
			flex-direction: column;
			align-items: center;
		}

		&_active {
			display: flex;
		}

	}

	&__form-choiced-area {
		display: flex;
		margin-right: 12px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.1;
		color: #111;

		@include md {
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 12px;
		}

		@include xs {
			margin-bottom: 6px;
			font-size: 16px;
		}
	}

	&__form-choiced-area-label {
		margin-right: 4px;
	}

	&__form-choiced-area-tows {
		margin-left: auto;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.1;
		color: #111;

		@include md {
			margin-right: auto;
		}

		@include xs {
			font-size: 14px;
		}
	}

	&__form-choiced-area-tows-count {
		font-weight: 700;
	}

	&__form-choiced-area-change {
		@include btn-reset;

		font-weight: 400;
		font-size: 16px;
		line-height: 1.1;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		color: #111;
		background-color: transparent;

		@include md {
			margin-bottom: 12px;
		}

		@include xs {
			margin-bottom: 8px;
		}
	}

	&__exact-form-send  {
		padding-left: 10px;
		padding-right: 10px;
	}
}