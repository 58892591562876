.algorithm {
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 64px;
	padding-bottom: 96px;
	background: #f1f4f9;

	@include lg {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include sm {
		margin-top: 48px;
		margin-bottom: 48px;
		padding-top: 48px;
		padding-bottom: 48px;
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 44px;
		counter-reset: algorithm-items;

		@include md {
			grid-template-columns: 100%;
		}

		@include xs {
			grid-gap: 39px;
		}
	}

	&__item {
		position: relative;
		border-radius: 20px;
		padding: 20px 24px 30px;
		box-shadow: 0 4px 32px 10px rgba(0, 0, 0, 0.02);
		background: #fff;

		@include xs {
			padding: 15px 20px 19px;
		}

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 100%;
			margin-left: 6px;
			display: none;
			width: 32px;
			aspect-ratio: 1;
			background-image: url("../img/icon-double-arrow.svg");
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			transform: translateY(-50%);

			@include md {
				top: 100%;
				left: 50%;
				margin-left: 0;
				margin-top: 7px;
				transform: translateX(-50%) rotate(90deg);
			}

			@include xs {
				margin-top: 5px;
			}
		}

		&:not(:last-child) {
			&::after {
				content: "";
				display: block;
			}
		}
	}

	&__item-name {
		padding-right: 80px;
		margin-bottom: 14px;
		min-height: 70px;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.3;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 22px;
			line-height: 1.1;
		}

		@include sm {
			padding-right: 100px;
			font-size: 18px;
			letter-spacing: normal;
			min-height: unset;
		}

		@include xs {
			margin-bottom: 17px;
		}
	}

	&__item-text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.4;
		letter-spacing: 0.01em;
		color: #1c1b14;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			padding-right: 66px;
			font-size: 15px;
			line-height: 1.3;
		}

		b {
			font-weight: 800;
		}
	}

	&__item-icon {
		position: absolute;
		right: 24px;
		top: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 69px;
		aspect-ratio: 1;
		background: radial-gradient(128% 1677% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);

		@include xs {
			right: 20px;
			top: 20px;
		}

		&::after {
			counter-increment: algorithm-items;
			content: "0" counter(algorithm-items);
			position: absolute;
			right: 0;
			top: -3px;
			font-weight: 700;
			font-size: 24px;
			line-height: 1.1;
			color: #111;

			@include lg {
				font-size: 24px;
			}
		}
	}

	&__item-icon-img {
		width: 32px;
		aspect-ratio: 1;
		object-fit: contain;
		object-position: center;
	}
}