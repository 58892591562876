.fixed-menu {
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity .2s ease-in;
	background: #fff;

	&__container {
		height: 100%;

		@include xs {
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	&__content {
		position: relative;
		margin-left: auto;
		// border-radius: 12px;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		transform: translateY(-100%);
		transition: transform .2s ease-in;

		@include md {
			padding-top: 96px;
			padding-bottom: 100px;
		}
	
		@include sm {
			padding-top: 82px;
			padding-bottom: 200px;
		}
	
		@include xs {
			padding-top: 65px;
		}
	}

	&__menu-list {
		@include list-reset;
		
	}


	&__menu-item {
		position: relative;

		&_has-child {
			padding-right: 50px;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 12px;
				width: 24px;
				aspect-ratio: 1;
				background-image: url("../img/icon-down.svg");
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
				cursor: pointer;
				transition: transform .2s;
			}
		}

		&_child-open {
			&::after {
				transform: rotate(180deg);
			}

			.fixed-menu__menu-child {
				max-height: none;
			}
		}
	}

	&__menu-link {
		display: inline-block;
		border-bottom: 1px solid rgba(238, 238, 238, 0.6);
		padding-top: 14px;
		padding-bottom: 14px;
		padding-right: 50px;
		width: 100%;
		font-weight: 800;
		font-size: 15px;
		line-height: 1.3;
		color: #333;
	}

	&__menu-child {
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;
	}

	&__menu-child-list {
		@include list-reset;

		padding-left: 10px;
		padding-bottom: 20px;
	}

	&__menu-child-link {
		display: inline-block;
		width: 100%;
		font-weight: 800;
		font-size: 15px;
		line-height: 1.3;
		color: #333;
	}
}

@include md {
	.open-menu {
		.fixed-menu {
			opacity: 1;
			pointer-events: all;

			&__content {
				transform: translateY(0);
			}
		}
	}
}