.custom-checkbox {
	display: inline-block;

	&__input {
		@include visually-hidden;

		&:checked {
			& + .custom-checkbox {
				&__label {
					&::before {
						border-color: rgb(250, 198, 11);
						background-image: url("../img/icon-check.svg"), radial-gradient(1677.62% 128.37% at 46.55% 50%, rgb(253, 225, 103) 0%, rgb(250, 198, 11) 100%);
					}
				}
			}
		}
	}

	&__label {
		position: relative;
		display: inline-block;
		padding-left: 40px;
		min-height: 24px;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.1;
		color: #111;

		@include lg {
			font-size: 16px;
		}

		@include sm {
			font-size: 15px;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 24px;
			aspect-ratio: 1;
			border-radius: 6px;
			border: 1px solid rgba(#111111, .4);
			cursor: pointer;
			background-position: center center;
			background-size: 14px auto, 100%;
			background-repeat: no-repeat;

			@include lg {
				top: -2px;
			}

			@include sm {
				top: 4px;
			}
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}